<template>
  <Form
    @submit="submit"
    class="d-flex flex-row justify-content-start align-items-end"
    ref="form" v-slot="{ meta }"
  >
    <HMTextInput
      class="mb-0"
      type="tel"
      rules="required"
      placeholder="+15017250605"
      :label="$t(title)"
      v-model="phone"
    />
    <HMButton
      class="ms-3"
      type="submit"
      color="brand"
      :loading="inProgress"
      :disabled="!meta.valid || inProgress"
    >
      {{ $t(buttonTitle) }}
    </HMButton>
  </Form>
</template>

<script>
import { Form } from 'vee-validate';
import { errorToMessage } from '@/utils/errors';
import { useStudioStore } from '@/stores';

export default {
  name: 'StudentPhoneNumberComponent',
  components: { Form },
  props: {
    title: {
      type: String,
      default: 'SETTINGS.LABEL.YOUR_PHONE_NUMBER',
    },
    buttonTitle: {
      type: String,
      default: 'GENERAL.BUTTON.UPDATE',
    },
  },
  data() {
    return {
      phone: null,
      inProgress: false,
    };
  },
  methods: {
    async submit() {
      try {
        const validatedPhone = this.$filterPhone(this.phone, {
          plus: true,
          brackets: false,
          space: false,
          dash: false,
          areaCode: true,
        });
        this.inProgress = true;
        try {
          await useStudioStore().addStudentPhoneNumber({
            studioURL: this.$studioURL,
            phone: validatedPhone,
          });
          this.$notify({
            group: 'app',
            type: 'success',
            title: this.$t('NOTIFICATIONS.TITLE.SUCCESS'),
            text: this.$t(
              'NOTIFICATIONS.TEXT.PHONE_NUMBER_SUCCESSFULLY_UPDATED'
            ),
          });
        } catch (error) {
          this.$notify({
            group: 'app',
            type: 'error',
            text: errorToMessage(error.response),
          });
        } finally {
          this.inProgress = false;
        }
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Invalid phone number',
          text:
            'Please include the plus sign, your area code, no brackets, no spaces, no dashes',
        });
      }
    },
  },
};
</script>

<style scoped></style>
